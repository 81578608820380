<template>
  <div class="app-container">
    <CrudTable
      entity="Vehicle"
      :columns="columns"
      :generate-summary="generateSummary"
      :permission-code="'MyVehicles'"
      :default-filters="{ onlineYN: 1 }"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        { field: 'name' },
        { field: 'code', label: 'vehicle.code' },
        { field: 'vehicleTypeName', label: 'common.vehicleType' },
        {
          field: 'onlineYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ]
    };
  },
  methods: {
    generateSummary({ name }) {
      return `${name}`;
    }
  }
};
</script>

<style scoped></style>
